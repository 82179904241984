import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RouterRoutes from './endpoints/RouterRoutes';
import './scss/style.scss';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import AppSpinner from './components/AppSpinner';
import AppRoutes from './endpoints/AppRoutes';
import { MobileProvider } from './contexts/MobileContext';

function App() {
  return (
    <MobileProvider>
      <BrowserRouter>
        <React.Suspense fallback={<AppSpinner />}>
          <ToastContainer autoClose={8000} />
          <Routes>
            {AppRoutes.map((route, index) => (
              route.component && (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component />}
                />
              )
            ))}
            {/* Handle 404 page */}
            <Route path="*" element={<RouterRoutes.Page404 />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
      <link rel="icon" href="https://sheramae.com/favicon.ico" />
    </MobileProvider>
  );
}

export default App;
