import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the Context
const MobileContext = createContext(false);

// Custom hook to use the context
export const useMobile = () => useContext(MobileContext);

export const MobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  // Function to check if the device is mobile
  const checkIfMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust width as needed
  };

  useEffect(() => {
    checkIfMobile(); // Check on initial load
    window.addEventListener('resize', checkIfMobile); // Listen to resize events

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <MobileContext.Provider value={isMobile}>
      {children}
    </MobileContext.Provider>
  );
};
