import React from 'react';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppConstants } from '../constants/AppContants';

const AppSpinner = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
      <h1 style={{ fontSize: 48 }}>{AppConstants.ApplicationName}</h1>
      <Spinner animation="border" />
      <div className="mt-2">{AppConstants.PreloaderContent}</div>
    </div>
  );
};

export default AppSpinner;
