export const AppConstants = {

  ApplicationName: "SHERAMAE.com",
  ApplicationWebSite: "https://sheramae.com/",
  ApplicationYear: 2024,
  BusinessEmail: "admin@sheramae.com",
  ContactNumber: "123-456-789",
  PreloaderContent: "Loading Website .... Please Wait .. ",

  Actor_Type: {
      USER: "User",
      ADMIN: "Admin"
  },
}