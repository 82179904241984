import React from 'react';
import RouterRoutes from "./RouterRoutes";

const Page404 = React.lazy(() => import("../views/pages/page404/Page404"));
const HomePage = React.lazy(() => import("../views/pages/landingPage/LandingPage"));
const AboutPage = React.lazy(() => import("../views/pages/about/About"));
const ShopPage = React.lazy(() => import("../views/pages/shop/Shop"));
const ResellerPage = React.lazy(() => import("../views/pages/reseller/Resellers"));

const AppRoutes = [
    { path: RouterRoutes.Home, name: "HomePage", component: HomePage, exact: true },
    { path: RouterRoutes.About, name: "AboutPage", component: AboutPage, exact: true },
    { path: RouterRoutes.Shop, name: "ShopPage", component: ShopPage, exact: true },
    { path: RouterRoutes.Resellers, name: "ResellerPage", component: ResellerPage, exact: true },
    { path: RouterRoutes.Page404, name: "404", component: Page404 },
]

export default AppRoutes;